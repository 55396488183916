
import { over } from 'stompjs';
import SockJS from 'sockjs-client';
import { useEffect, useState } from 'react';
var stompClient = null;
export default function ReceiveNotification() {

    useEffect(() => {
        askNotificationPermission()
        connect();
    }, [])

    //TODO fetch username either form sessionStorage or pass as userContext
    let username = sessionStorage.getItem('username')


    const [pushNotification, setPushNotification] = useState([])

    const connect = () => {
        let Sock = new SockJS('https://apichat.omsent.com/ws');
        // let Sock = new SockJS('http://localhost:9595/ws');

        stompClient = over(Sock);
        stompClient.connect({}, onConnected, onError);
    }
    const onConnected = () => {
        if (username !== null && username !== undefined) {
            stompClient.subscribe('/user/' + username + '/notification', onPrivateMessage);
        }


    }
    const onError = (err) => {
        console.log(err);

    }

    const onPrivateMessage = (payload) => {
        console.log(payload);

        // setPushNotification(data => setPushNotification([...data,payload.body]))
        pushNotification.push(payload.body)
        setPushNotification([...pushNotification]);
        showPushNotification({ title: 'Omsent Notification', body: payload?.body })

        // alert(payload?.body)
    }

    function askNotificationPermission() {
        if ('Notification' in window) {
            Notification.requestPermission().then(permission => {
                if (permission === 'granted') {
                    console.log('Notification permission granted');
                } else {
                    console.log('Notification permission denied');

                    //TODO force user to allow notification
                }
            });
        }
    }


    function showPushNotification(notification) {
        if (Notification.permission === 'granted') {
            new Notification(notification?.title, {
                body: notification.body,
                icon: 'https://images.omsent.com/api/image/1668925227769.png', // Optional icon for the notification
            });
        }
    }


    return (<>
        <h1>Receive Notification</h1>
        <p>Auto connect on page load</p>
        <p>Ask for push notification permission</p>
        <p>Supposed to be connected all time in background for push notification</p>


        <p>Anyone can send this user notification</p>
        <p>Data misses if user is offline</p>
        <p>Online and offline can be tracked here</p>
        {/* <button onClick={connect}>Connect</button> */}
        <h2>List Push Notification for user {username}</h2>
        {pushNotification.map((push, index) => <li key={index}>{JSON.stringify(push)}</li>)}

    </>)
}