import React from 'react'


import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import ChatRoom from './components/ChatRoom'
import ReceiveNotification from './ReceiveNotification';
import ReceiveLocation from './ReceiveLocation';
import PushNotification from './PushNotification';
import SendLocation from './SendLocation';

const App = () => {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* <Route index element={<ChatRoom />} /> */}
        {/* <Route path="order" element={<OrderLocation />} /> */}
        {/* <Route path="update" element={<UpdateLocation />} /> */}
        <Route path="chat-room" element={<ChatRoom />} />

        <Route path="receive-notification" element={<ReceiveNotification />} />
        <Route path="receive-location" element={<ReceiveLocation />} />

       
        <Route path="push-notification" element={<PushNotification />} />
        <Route path="send-location" element={<SendLocation />} />
        



        <Route path="*" element={<p>Page not found !!</p>} />
      </Route>
    </Routes>
  </BrowserRouter>
 
  )
}

export default App;

