
import { over } from 'stompjs';
import SockJS from 'sockjs-client';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
var stompClient = null;
export default function ReceiveLocation() {

    useEffect(() => {
        connect();
    }, [])

    //TODO fetch orderingId either form sessionStorage or pass as query param
    //TODO but at same time there can be multiple orderingId under tracking for same user QUERY param seems better option can handle n no of tracking
    const [searchParams, setSearchParams] = useSearchParams();
       // let  orderingId = 30101 
    // searchParams.get("orderingId")

    const [errorMessage,setErrorMessage] = useState('');
    
 


    const [orderLocation, setOrderLocation] = useState([])

    const connect = () => {
        let Sock = new SockJS('https://apichat.omsent.com/ws');
        // let Sock = new SockJS('http://localhost:9595/ws');


        stompClient = over(Sock);
        stompClient.connect({}, onConnected, onError);
    }
    const onConnected = () => {
        //check if query param has orderId or not
        if (searchParams.get("orderingId") != undefined && searchParams.get("orderingId") != null) {
            stompClient.subscribe('/order/' + searchParams.get("orderingId"), onPrivateMessage);
        } else {
            setErrorMessage('orderingId is missing in query param')
        }


    }
    const onError = (err) => {
        console.log(err);

    }

    const onPrivateMessage = (payload) => {
        console.log(payload);

        // setPushNotification(data => setPushNotification([...data,payload.body]))
        orderLocation.push(payload.body)
        setOrderLocation([...orderLocation]);

    }




    return (<>

        {/* <button onClick={connect}>Connect</button> */}
        <p>{errorMessage}</p>
        <h2>Receive  Location update for order id {searchParams.get("orderingId")}</h2>
        {orderLocation.map((push, index) => <li key={index}>{JSON.stringify(push)}</li>)}



    </>)
}