import React, { useEffect, useState } from 'react';
import SockJS from 'sockjs-client';
import { over } from 'stompjs';

var stompClient = null;

const SendLocation = () => {

  const [payloadData, setPayloadData] = useState({
    "lat": "19822.01",
    "lng": "19833.99",
    "description": "multiple order in hand",
    "orderingId": 30101
  })
  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
    error: null,
  });

  useEffect(() => {

    connect();
    // Function to handle error
    const handleError = (error) => {
      setLocation((prevLocation) => ({
        ...prevLocation,
        error: error.message,
      }));
    };

    const handleSuccess = (position) => {
      const { latitude, longitude } = position.coords;

      // Send the updated location to the WebSocket server or API
      if (stompClient) {
        stompClient.send('/app/order', {}, JSON.stringify({ ...payloadData, lat: latitude, lng: longitude })); // Send geocoordinates to the server


      }

      // Update the state with new location
      setLocation({ latitude, longitude, error: null });
    };


    // Check if geolocation is available in the browser
    if (navigator.geolocation) {
      // Watch for geolocation changes (updates the location when the user moves)
      const watchId = navigator.geolocation.watchPosition(handleSuccess, handleError, {
        enableHighAccuracy: true,  // Use high accuracy if possible
        maximumAge: 0,             // Do not use cached position
        timeout: 5000,             // 5 seconds timeout
      });

      // Cleanup function to clear the watch when the component unmounts
      return () => {
        navigator.geolocation.clearWatch(watchId);
      };
    } else {
      setLocation((prevLocation) => ({
        ...prevLocation,
        error: 'Geolocation is not supported by this browser.',
      }));
    }
  }, []); // Empty dependency array means this effect runs once when the component mounts


  const connect = () => {
    let Sock = new SockJS('https://apichat.omsent.com/ws');
    // let Sock = new SockJS('http://localhost:9595/ws');


    stompClient = over(Sock);
    // stompClient.connect({}, onConnected, onError);
  }


  const handleSuccess = () => {
    const { latitude, longitude } = location;

    // Send the updated location to the WebSocket server or API
    if (stompClient) {
      // stompClient.send('/app/order', {}, JSON.stringify(payloadData));
      stompClient.send('/app/order', {}, JSON.stringify({ ...payloadData, lat: latitude, lng: longitude })); // Send geocoordinates to the server

    }

    // Update the state with new location
    setLocation({ latitude, longitude, error: null });
  };

  return (
    <div>
      <h1>Send Location</h1>
      {location.error ? (
        <p>Error: {location.error}</p>
      ) : (
        <div>
          <p>Latitude: {location.latitude}</p>
          <p>Longitude: {location.longitude}</p>

          <input type="text" name='orderingId' onChange={(event) => setPayloadData({ ...payloadData, orderingId: event?.target.value })} />
          <button onClick={handleSuccess}>Submit location</button>
        </div>
      )}
    </div>
  );
};

export default SendLocation;
