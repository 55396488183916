
import { over } from 'stompjs';
import SockJS from 'sockjs-client';
import { useEffect, useState } from 'react';
var stompClient = null;
export default function PushNotification() {

    useEffect(() => {
        connect();
    }, [])
    let payload = {
        "senderName": "Admin",
        "receiverName": "Ashish",
        "message": "New Notification frompostman"

    }

    const connect = () => {
        let Sock = new SockJS('https://apichat.omsent.com/ws');
        // let Sock = new SockJS('http://localhost:9595/ws');

        stompClient = over(Sock);
        stompClient.connect({}, onConnected, onError);
    }
    const onConnected = () => {
        let username = 'Ashish'
        // stompClient.subscribe('/user/' + username + '/private', onPrivateMessage);
        // stompClient.subscribe('/user/' + username + '/notification', onPushNotification);


    }
    const onError = (err) => {
        console.log(err);

    }

    const onPushNotification = () => {
        let username = 'Ashish'
        console.log(payload);

        // setPushNotification(data => setPushNotification([...data,payload.body]))
        stompClient.send('/user/' + username + '/notification', payload);

    }




    return (<>
        <h1>Push Notification</h1>

        <button onClick={onPushNotification}>Connect</button>



    </>)
}