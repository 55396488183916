import { useEffect } from "react";

import { over } from 'stompjs';
import SockJS from 'sockjs-client';

import { Outlet, Link } from "react-router-dom";
import './Layout.css'
const Layout = () => {

  return (
    <>
      <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          {/* <li>
            <Link to="/order">OrderLocation</Link>
          </li>
          <li>
            <Link to="/update">UpdateLocation</Link>
          </li> */}
         
          <li>
            <Link to="/receive-notification">Receive Notification</Link>
          </li>
          <li>
            <Link to="/receive-location?orderingId=30101" >LocationTracker</Link>
          </li>
        </ul>
        <span>-------</span>
        <ul>
      
          <li>
            <Link to="/send-location">Send Location</Link>
          </li>
          <li>
            <Link to="/push-notification">Push Notification</Link>
          </li>
          <li>
          </li>
        </ul>
        <span>-----</span>
        <ul>
        <li>
            <Link to="/chat-room">Chat Room</Link>
          </li>
        </ul>
      </nav>

      <Outlet />
    </>
  )
};

export default Layout;